import styled from 'styled-components';
import { device } from '../../../styles/breakpoints';
import { BodyText1, Header1 } from '../../atoms/Fonts';

export const Title = styled(Header1)`
  color: white;
  margin-bottom: 24px;
`;

export const LoginPageContainer = styled.div`
  height: 100%;
  display: flex;
`;

export const SignUpWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`;

export const SignUpQuestion = styled(BodyText1)`
  color: ${(props) => props.theme.colors.text.default};
  margin-right: 8px;
`;

export const RightSideInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 80px 20px;
  justify-content: start;
  align-items: center;

  @media ${device.tablet} {
    margin: 80px 50px;
  }

  @media ${device.mobile} {
    margin: 80px 26px;
  }
`;

export const LoginFormWrapper = styled.div`
  max-width: 352px;
  width: 100%;
  // width: 352px;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 40px;
`;
