import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
} from 'formik';
import {
  ButtonWrapper,
  FieldWrapper,
  ForgotPasswordLabel,
  LoginFormContainer,
} from './styled';

import * as yup from 'yup';
import TextField from '../../atoms/TextInput';
import Button from '../../atoms/Button';
import PasswordField from '../PasswordField/PasswordField';
import Link from 'next/link';

interface LoginFormProps {
  onSubmit: (values: LoginFormSchema) => Promise<void>;
  initialErrors?: FormikErrors<LoginFormSchema>;
}

export interface LoginFormSchema {
  username: string;
  password: string;
}

export const schema: yup.SchemaOf<LoginFormSchema> = yup.object().shape({
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required'),
});

const LoginForm = (props: LoginFormProps) => {
  return (
    <LoginFormContainer>
      <Formik
        onSubmit={(values) => props.onSubmit(values)}
        initialValues={{ username: '', password: '' }}
        validationSchema={schema}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({ errors, isSubmitting }) => (
          <Form>
            <FieldWrapper>
              <Field name='username'>
                {(field: FieldProps) => (
                  <TextField
                    name='username'
                    size={'lg'}
                    {...field}
                    placeholder={'Email'}
                    type='email'
                    errorMessage={
                      errors['username'] || props.initialErrors?.username
                    }
                  />
                )}
              </Field>
            </FieldWrapper>
            <FieldWrapper>
              <Field name='password'>
                {(field: FieldProps) => (
                  <PasswordField
                    name='password'
                    size={'lg'}
                    {...field}
                    placeholder={'Password'}
                    errorMessage={errors['password']}
                  />
                )}
              </Field>
            </FieldWrapper>
            <Link href='/auth/forgot-password'>
              <ForgotPasswordLabel>Forgot Password?</ForgotPasswordLabel>
            </Link>
            <ButtonWrapper>
              <Button
                size={'lg'}
                variant={'primary'}
                block
                type='submit'
                disabled={isSubmitting}
              >
                LOG IN
              </Button>
            </ButtonWrapper>
          </Form>
        )}
      </Formik>
    </LoginFormContainer>
  );
};

export default LoginForm;
