import Head from 'next/head';
import LoginPage from '../../components/pages/Login';

import { withPublicOnlyRoute } from '../../lib/auth/publicOnlyRoute';
import { withSessionSsr } from '../../lib/withSession';

export default function Login() {
  return (
    <>
      <Head>
        <title>Dealer Portal - Login</title>
      </Head>
      <LoginPage />
    </>
  );
}

export const getServerSideProps = withSessionSsr(
  async function getServerSideProps(ctx) {
    return withPublicOnlyRoute(ctx, (ctx) => {
      return {
        props: {},
      };
    });
  }
);
