import styled from 'styled-components';
import { BodyText1, SubTitle } from '../../atoms/Fonts';

export const LoginFormContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const FieldWrapper = styled.div`
  :not(:nth-child(2)) {
    margin: 24px 0;
  }
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.div``;

export const ForgotPasswordLabel = styled(BodyText1)`
  color: ${(props) => props.theme.colors.text.default};
  float: right;
  margin: 8px 0 24px 0;
`;
