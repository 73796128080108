import Link from 'next/link';
import { SITEMAP } from '../../../config/sitemap';
import { api } from '../../../lib/axios/axios-client';

import Button from '../../atoms/Button';
import { SideBannerContainer } from '../../containers';
import SideBannerContentContainer from '../../containers/SideBannerContentContainer';
import LogoWithTitle from '../../molecules/LogoWithTitle';

import LoginForm from '../../organisms/LoginForm';
import {
  HeaderWrapper,
  LoginFormWrapper,
  LoginPageContainer,
  RightSideInner,
  SignUpQuestion,
  SignUpWrapper,
  Title,
} from './styled';
import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';

interface LoginPageProps {}

const LoginPage = (props: LoginPageProps) => {
  const router = useRouter();

  const mutation = useMutation({
    mutationFn: (input: { username: string; password: string }) =>
      api.post('/auth/login', {
        username: input.username,
        password: input.password,
      }),
    onSuccess: (data) => {
      if (data.data.ok === true) {
        router.push('/');
      }
      if (data.data.error === 'pending_approval') {
        router.push(SITEMAP.PENDING_APPROVAL);
      }
    },
    onError: () => {},
  });

  return (
    <LoginPageContainer>
      <SideBannerContainer
        maxImageWidth={700}
        style={{
          backgroundImage: `url(/assets/images/side-banners/login-banner.png)`,
        }}
      >
        <Title>
          THE FUTURE OF SECURITY <br />
          IS ADVANCED <br />
          VIDEO MONITORING
        </Title>
      </SideBannerContainer>
      <SideBannerContentContainer>
        <RightSideInner>
          <HeaderWrapper>
            <LogoWithTitle title='Welcome Back' />
          </HeaderWrapper>
          <LoginFormWrapper>
            <LoginForm
              onSubmit={async (values) => {
                await mutation.mutateAsync(values);
              }}
              initialErrors={
                mutation.isError
                  ? { username: 'Invalid Email/Password' }
                  : undefined
              }
            />
          </LoginFormWrapper>
          {/* <SignUpWrapper>
            <SignUpQuestion>Don't have an account?</SignUpQuestion>
            <Link href={SITEMAP.REGISTER} legacyBehavior>
              <a>
                <Button size='md' variant='link' type='button'>
                  SIGN UP
                </Button>
              </a>
            </Link>
          </SignUpWrapper> */}
        </RightSideInner>
      </SideBannerContentContainer>
    </LoginPageContainer>
  );
};

export default LoginPage;
